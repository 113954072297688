import CryptoJS from 'crypto-js';

import overall from '~/data/overall';

export function decrypt(text, key = String(overall.modifyTime)) {
  if (!Number.isNaN(Number(text))) {
    return text;
  }
  try {
    const bytes = CryptoJS.AES.decrypt(String(text), key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('error', text, error);
    return 0;
  }
}

export function decryptV2Data(data) {
  return data.map(({ entries, ...rest }) => {
    return {
      ...rest,
      entries: entries.map(([date, num], i) => [date, i === entries.length - 1 ? Number(decrypt(num)) : num]),
    };
  });
}
