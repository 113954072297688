import _ from 'lodash';
import fromEntries from 'object.fromentries';
import axios from 'axios';
import { Modal } from 'antd';
import i18n from 'i18next';

import { getLatestDate } from '~/utils.js';
import { decryptV2Data } from '~/utils/Crypto';
import USCaseV2 from '~/data/US-Case-V2';
import CanadaCaseV2 from '~/data/Canada-Case-V2';
import USCuredV2 from '~/data/US-Cured-V2';
import CanadaCuredV2 from '~/data/Canada-Cured-V2';
import USDeathV2 from '~/data/US-Death-V2';
import CanadaDeathV2 from '~/data/Canada-Death-V2';
import USCountyStats from '~/data/US-County-Stats';
import CanadaCountyStats from '~/data/Canada-County-Stats';
import USMap from '~/data/US-Stats-map';
import CanadaMap from '~/data/Canada-Stats-map';
import populationData from '~/data/state-population';
import regionData from '~/data/region-data-v2.json';
import USVaccineDistribution from '~/data/us-vaccine-distribution';
import USVaccineDosesAdmin from '~/data/us-vaccine-doses-admin';
import WorldVaccineDosesAdmin from '~/data/world-vaccine-doses-admin';
import usStatesReversed from '~/data/us-states-reversed';
import { getCumulativeData } from '~/utils/WorldHelper';
import { rehyrateUSData, rehyrateCanadaData, KeyDates } from '~/common';

export function getCachedData(key, getData) {
  if (global[key] == null) {
    const data = getData();
    global[key] = data;
  }
  return global[key];
}

export function deleteCachedData(key) {
  if (global[key]) {
    delete global[key];
  }
}

export function getConfirmedCaseData(country) {
  return getCachedData(`confirmed-case-data-${country}`, () =>
    getCaseData(
      {
        us: () =>
          global['us-confirmed-history-1'] && global['us-confirmed-history-2']
            ? decryptV2Data(
                mergeUSData(
                  mergeUSData(
                    rehyrateUSData(USCaseV2, KeyDates.US_LAZY_LOAD_START_DATE),
                    rehyrateUSData(global['us-confirmed-history-2'], KeyDates.US_CASE_START_DATE)
                  ),
                  rehyrateUSData(global['us-confirmed-history-1'], KeyDates.US_LAZY_LOAD_START_DATE_1)
                )
              )
            : decryptV2Data(rehyrateUSData(USCaseV2, KeyDates.US_LAZY_LOAD_START_DATE)),
        ca: () =>
          global['canada-confirmed-history-1'] && global['canada-confirmed-history-2']
            ? decryptV2Data(
                mergeUSData(
                  mergeUSData(
                    rehyrateCanadaData(CanadaCaseV2, KeyDates.CA_LAZY_LOAD_START_DATE),
                    rehyrateCanadaData(global['canada-confirmed-history-2'], KeyDates.CA_CASE_START_DATE)
                  ),
                  rehyrateCanadaData(global['canada-confirmed-history-2'], KeyDates.CA_CASE_START_DATE_1)
                )
              )
            : decryptV2Data(rehyrateCanadaData(CanadaCaseV2, KeyDates.CA_LAZY_LOAD_START_DATE)),
      }[country]()
    )
  );
}

export function getCuredCaseData(country) {
  return getCachedData(`cured-case-data-${country}`, () =>
    getCaseData(
      {
        us: () =>
          global['us-cured-history']
            ? decryptV2Data(
                mergeUSData(
                  rehyrateUSData(USCuredV2, KeyDates.US_LAZY_LOAD_START_DATE),
                  rehyrateUSData(global['us-cured-history'], KeyDates.US_CURED_START_DATE)
                )
              )
            : decryptV2Data(rehyrateUSData(USCuredV2, KeyDates.US_LAZY_LOAD_START_DATE)),
        ca: () =>
          global['canada-cured-history']
            ? decryptV2Data(
                mergeUSData(
                  rehyrateCanadaData(CanadaCuredV2, KeyDates.CA_LAZY_LOAD_START_DATE),
                  rehyrateCanadaData(global['canada-cured-history'], KeyDates.CA_CURED_START_DATE)
                )
              )
            : decryptV2Data(rehyrateCanadaData(CanadaCuredV2, KeyDates.CA_LAZY_LOAD_START_DATE)),
      }[country]()
    )
  );
}

export function getDeathCaseData(country) {
  return getCachedData(`death-case-data-${country}`, () =>
    getCaseData(
      {
        us: () =>
          global['us-deaths-history']
            ? decryptV2Data(
                mergeUSData(
                  rehyrateUSData(USDeathV2, KeyDates.US_LAZY_LOAD_START_DATE),
                  rehyrateUSData(global['us-deaths-history'], KeyDates.US_DEATH_START_DATE)
                )
              )
            : decryptV2Data(rehyrateUSData(USDeathV2, KeyDates.US_LAZY_LOAD_START_DATE)),
        ca: () =>
          global['canada-deaths-history']
            ? decryptV2Data(
                mergeUSData(
                  rehyrateCanadaData(CanadaDeathV2, KeyDates.CA_LAZY_LOAD_START_DATE),
                  rehyrateCanadaData(global['canada-deaths-history'], KeyDates.CA_DEATH_START_DATE)
                )
              )
            : decryptV2Data(rehyrateCanadaData(CanadaDeathV2, KeyDates.CA_LAZY_LOAD_START_DATE)),
      }[country]()
    )
  );
}

export function getConfirmedIncreaseData(country) {
  return getCachedData(`confirmed-increase-data-${country}`, () => getIncreaseData(getConfirmedCaseData(country)));
}

export function getConfirmedOneWeekIncreaseData(country) {
  return getCachedData(`confirmed-one-week-increase-data-${country}`, () =>
    getOneWeekIncreaseData(getConfirmedCaseData(country))
  );
}

export function getConfirmedTwoWeekIncreaseData(country) {
  return getCachedData(`confirmed-two-week-increase-data-${country}`, () =>
    getTwoWeekIncreaseData(getConfirmedCaseData(country))
  );
}

export function getCuredIncreaseData(country) {
  return getCachedData(`cured-increase-data-${country}`, () => getIncreaseData(getCuredCaseData(country)));
}

export function getDeathIncreaseData(country) {
  return getCachedData(`death-increase-data-${country}`, () => getIncreaseData(getDeathCaseData(country)));
}

export function formatCountyCase(countyCase) {
  const { date, value } = countyCase.entries.reduce(
    (r, [k, v], i, array) => {
      r.date.push(k);
      r.value.push(v);
      return r;
    },
    {
      date: [],
      value: [],
    }
  );
  const [last, secondLast] = Array.from({ length: 2 }).map((_, i) => value[value.length - 1 - i]);
  const oneWeekAgo = _.nth(value, -9) || 0; // Excludes today (data not complete)
  const twoWeeksAgo = _.nth(value, -16) || 0; // Excludes today (data not complete)
  const county = countyCase.county;
  let increased = date[date.length - 1] === getLatestDate() ? last - (secondLast || 0) : 0;
  if (
    county &&
    (county[0].toLowerCase().includes('toronto public') || county[0].toLowerCase().includes('peel region'))
  ) {
    increased = 0;
  }
  return {
    date,
    value,
    cumulative: last,
    increase: increased,
    oneWeekIncrease: secondLast - oneWeekAgo,
    twoWeekIncrease: secondLast - twoWeeksAgo,
    state_name: countyCase.state_name,
    county: county,
  };
}

export function getCaseData(data) {
  const byState = _.groupBy(data.map(formatCountyCase), 'state_name');
  return fromEntries(
    Object.entries(byState).map(([k, v]) => {
      return [
        k,
        {
          date: v[0].date,
          value: _.zipWith(...v.map(cases => cases.value), (...arrays) => _.sum(arrays)),
          cumulative: _.sumBy(v, 'cumulative'),
          increase: _.sumBy(v, 'increase'),
          oneWeekIncrease: _.sumBy(v, 'oneWeekIncrease'),
          twoWeekIncrease: _.sumBy(v, 'twoWeekIncrease'),
          counties: v.reduce((r, { county, ...rest }) => {
            r[county] = rest;
            return r;
          }, {}),
        },
      ];
    })
  );
}

export function getIncreaseData(data) {
  return Object.values(data).reduce((r, d) => {
    return r + d.increase;
  }, 0);
}

export function getOneWeekIncreaseData(data) {
  return Object.values(data).reduce((r, d) => {
    return r + d.oneWeekIncrease;
  }, 0);
}

export function getTwoWeekIncreaseData(data) {
  return Object.values(data).reduce((r, d) => {
    return r + d.twoWeekIncrease;
  }, 0);
}

export function getStateMapData(stateName, country = 'us') {
  const stateData = getConfirmedCaseData(country)[stateName];
  const countyCuredData = getCuredCaseData(country)[stateName]?.counties;
  const countyDeathData = getDeathCaseData(country)[stateName]?.counties;
  if (stateData) {
    return _.mapValues(stateData.counties, (v, k) => ({
      value: v.cumulative,
      curedCount: countyCuredData?.[k]?.cumulative,
      deadCount: countyDeathData?.[k]?.cumulative,
      increaseConfirmed: v.increase,
      increaseCured: countyCuredData?.[k]?.increase,
      increaseDead: countyDeathData?.[k]?.increase,
    }));
  }
  return {};
}

export function getTrendChartData(data, stateName) {
  const stateData = data[stateName];
  if (stateData) {
    return {
      total: stateData.data,
      name: stateName,
    };
  }
  return { total: [] };
}

function getIncreaseArray(data) {
  return data.map((v, i, list) => v - list[i - 1] || 0);
}

function removeFirstItem(data) {
  return _.mapValues(data, v => v.slice(1));
}

export function toChartData({ value = [], date = [] } = {}) {
  return removeFirstItem({
    total: value,
    increase: getIncreaseArray(value),
    x: date,
  });
}

function toCountryData(data) {
  const caseArray = Object.values(data);
  return {
    value: _.zipWith(...caseArray.map(d => d.value), (...arrays) => _.sum(arrays)),
    date: caseArray[0].date,
  };
}

function getCountyData(data, countyName) {
  if (data) {
    return (
      data[countyName] ||
      Object.entries(data)
        .filter(([k]) => k.split('--')[0] === countyName)
        .map(([k, v]) => v)
        .reduce(
          (r, v) => {
            r.cumulative += v.cumulative;
            r.increase += v.increase;
            r.date = v.date;
            r.value = v.value.map((d, i) => d + (r.value[i] || 0));
            return r;
          },
          {
            value: [],
            increase: 0,
            cumulative: 0,
          }
        )
    );
  }
  return undefined;
}

export function getLineChartData(data, stateName, countyName) {
  let selectedData = undefined;
  if (countyName) {
    selectedData = getCountyData(data[stateName]?.counties, countyName);
  } else if (stateName) {
    selectedData = data[stateName];
  } else {
    selectedData = toCountryData(data);
  }
  return toChartData(selectedData);
}

export function formatStateTableData(confirmedData, deathCaseData, curedCaseData, provinces, vaccineData) {
  const countyStats = _.merge(CanadaCountyStats, USCountyStats);
  return Object.entries(confirmedData)
    .map(([stateName, { counties, cumulative, increase, oneWeekIncrease, twoWeekIncrease }]) => {
      const [curedStateCase, deathStateCase] = [curedCaseData?.[stateName], deathCaseData?.[stateName]];
      const vaccineAdministeredItem = vaccineData ? _.find(vaccineData, item => item.name === stateName) : null;
      return {
        confirmed: cumulative || 0,
        increaseConfirmed: increase || 0,
        oneWeekIncreaseConfirmed: oneWeekIncrease || 0,
        twoWeekIncreaseConfirmed: twoWeekIncrease || 0,
        cured: curedStateCase?.cumulative || 0,
        increaseCured: curedStateCase?.increase || 0,
        dead: deathStateCase?.cumulative || 0,
        increaseDead: deathStateCase?.increase || 0,
        population: (populationData[stateName] || '').replace(/,/g, ''),
        ...(provinces[stateName] ? provinces[stateName][0] : { provinceShortName: stateName }),
        counties: Object.entries(counties)
          .map(([countryName, v]) => {
            return {
              confirmed: v.cumulative || 0,
              increaseConfirmed: v.increase || 0,
              oneWeekIncreaseConfirmed: v.oneWeekIncrease || 0,
              twoWeekIncreaseConfirmed: v.twoWeekIncrease || 0,
              cured: curedStateCase?.counties?.[countryName]?.cumulative || 0,
              increaseCured: curedStateCase?.counties?.[countryName]?.increase || 0,
              dead: deathStateCase?.counties?.[countryName]?.cumulative || 0,
              increaseDead: deathStateCase?.counties?.[countryName]?.increase || 0,
              name: countryName,
              ...countyStats[`${stateName}-${countryName}`],
            };
          })
          .sort((a, b) => b.confirmed - a.confirmed),
        vaccineAdministered: vaccineAdministeredItem?.value,
        vaccineAdministeredIncrease: vaccineAdministeredItem?.doses_admin_total_increase,
      };
    })
    .sort((a, b) => b.confirmed - a.confirmed);
}

export function getStateTableCountryData(data) {
  return data.reduce(
    (r, v) => {
      r.dead += v.dead;
      r.increaseDead += v.increaseDead;
      r.confirmed += v.confirmed;
      r.increaseConfirmed += v.increaseConfirmed;
      r.oneWeekIncreaseConfirmed += v.oneWeekIncreaseConfirmed;
      r.twoWeekIncreaseConfirmed += v.twoWeekIncreaseConfirmed;
      r.cured += v.cured;
      r.increaseCured += v.increaseCured;
      return r;
    },
    {
      dead: 0,
      increaseDead: 0,
      confirmed: 0,
      increaseConfirmed: 0,
      oneWeekIncreaseConfirmed: 0,
      twoWeekIncreaseConfirmed: 0,
      cured: 0,
      increaseCured: 0,
    }
  );
}

export function getCountryData(country) {
  return (
    {
      us: {
        confirmedData: getConfirmedCaseData('us'),
        deathCaseData: getDeathCaseData('us'),
        curedCaseData: getCuredCaseData('us'),
        mapData: USMap,
        country,
      },
      ca: {
        confirmedData: getConfirmedCaseData('ca'),
        deathCaseData: getDeathCaseData('ca'),
        curedCaseData: getCuredCaseData('ca'),
        mapData: CanadaMap,
        country,
      },
    }[country] || {}
  );
}

export function getStateTableData(country) {
  if (['ca', 'us'].includes(country)) {
    const { confirmedData, deathCaseData, curedCaseData, mapData } = getCountryData(country);
    return getCachedData(`state-table-${country}`, () =>
      formatStateTableData(
        confirmedData,
        deathCaseData,
        curedCaseData,
        _.groupBy(mapData, 'provinceShortName'),
        country === 'us' ? getUSVaccineAdministeredData() : null
      )
    );
  }
  return getCumulativeData(regionData[country], country);
}

function mergeUSData(primaryData, secondaryData) {
  const result = [];
  _.map(primaryData, primaryItem => {
    const matchedItem = _.find(
      secondaryData,
      item => item.state_name[0] === primaryItem.state_name[0] && item.county[0] === primaryItem.county[0]
    );
    if (matchedItem) {
      result.push({ ...primaryItem, entries: _.concat(matchedItem.entries, primaryItem.entries) });
    } else {
      result.push(primaryItem);
    }
  });
  return result;
}

export async function checkAndFetchFullUSCAData(callback, country) {
  try {
    if (
      (country === 'us' && global['us-confirmed-history-1'] && global['us-deaths-history-1']) ||
      (country === 'ca' &&
        global['canada-confirmed-history'] &&
        global['canada-deaths-history'] &&
        global['canada-cured-history'])
    ) {
      callback();
      return;
    }
    Modal.confirm({
      title:
        i18n.language === 'zh' ? (
          <div>
            请选择要查看的每日新增数据范围
            <br />
            <ul style={{ paddingTop: 10, paddingLeft: 18, fontSize: 12 }}>
              <li>近期数据: 加载速度更快。</li>
              <li>全部数据: 对设备性能和网络要求较高，加载时间更长。</li>
            </ul>
          </div>
        ) : (
          <div>
            Please choose the daily increase date range to view
            <br />
            <ul style={{ paddingTop: 10, paddingLeft: 18, fontSize: 12 }}>
              <li>View recent data: takes less time to load.</li>
              <li>View all data: requires better device and network performance, and may take longer to load.</li>
            </ul>
          </div>
        ),
      okText: i18n.language === 'zh' ? '近期数据' : 'View recent data',
      cancelText: i18n.language === 'zh' ? '全部数据' : 'View all data',
      onOk: () => callback(),
      onCancel: async () => {
        await (country === 'us' ? fetchFullUSData() : fetchFullCanadaData());
        callback();
      },
    });
  } catch (err) {}
}

async function fetchFullUSData() {
  try {
    const [confirmedRes1, deathsRes1, confirmedRes2, deathsRes2] = await Promise.all([
      axios.get('/resources/US-Case-210831.json'),
      axios.get('/resources/US-Death-210831.json'),
      axios.get('/resources/US-Case-210901-220824.json'),
      axios.get('/resources/US-Death-210901-220824.json'),
    ]);
    global['us-confirmed-history-1'] = confirmedRes1.data;
    global['us-deaths-history-1'] = deathsRes1.data;
    global['us-confirmed-history-2'] = confirmedRes2.data;
    global['us-deaths-history-2'] = deathsRes2.data;
    deleteCachedData('confirmed-case-data-us');
    deleteCachedData('death-case-data-us');
  } catch (err) {}
}

async function fetchFullCanadaData() {
  try {
    const [confirmedRes1, deathsRes1, curedRes1, confirmedRes2, deathsRes2, curedRes2] = await Promise.all([
      axios.get('/resources/Canada-Case-210831.json'),
      axios.get('/resources/Canada-Death-210831.json'),
      axios.get('/resources/Canada-Cured-210831.json'),
      axios.get('/resources/Canada-Case-210901-220824.json'),
      axios.get('/resources/Canada-Death-210901-220824.json'),
      axios.get('/resources/Canada-Cured-210901-220824.json'),
    ]);
    global['canada-confirmed-history-1'] = confirmedRes1.data;
    global['canada-deaths-history-1'] = deathsRes1.data;
    global['canada-cured-history-1'] = curedRes1.data;
    global['canada-confirmed-history-2'] = confirmedRes2.data;
    global['canada-deaths-history-2'] = deathsRes2.data;
    global['canada-cured-history-2'] = curedRes2.data;
    deleteCachedData('confirmed-case-data-ca');
    deleteCachedData('death-case-data-ca');
    deleteCachedData('cured-case-data-ca');
  } catch (err) {}
}

export function getUSVaccineDistributedData() {
  return getCachedData(`us-vaccine-distributed-data`, () =>
    USVaccineDistribution.map(item => ({
      name: usStatesReversed[_.replace(item.jurisdiction, /\*/g, '')],
      total_pfizer_allocation_first_dose_shipments: _.toNumber(
        _.replace(item.total_pfizer_allocation_first_dose_shipments || '', /,/g, '')
      ),
      // total_pfizer_allocation_second_dose_shipments: _.toNumber(
      //   _.replace(item.total_pfizer_allocation_second_dose_shipments || '', /,/g, '')
      // ),
      total_moderna_allocation_first_dose_shipments: _.toNumber(
        _.replace(item.total_moderna_allocation_first_dose_shipments || '', /,/g, '')
      ),
      // total_moderna_allocation_second_dose_shipments: _.toNumber(
      //   _.replace(item.total_moderna_allocation_second_dose_shipments || '', /,/g, '')
      // ),
      value:
        _.toNumber(_.replace(item.total_pfizer_allocation_first_dose_shipments || '', /,/g, '')) +
        _.toNumber(_.replace(item.total_moderna_allocation_first_dose_shipments || '', /,/g, '')),
    }))
  );
}

export function getUSVaccineAdministeredData() {
  return getCachedData(`us-vaccine-administered-data`, () =>
    USVaccineDosesAdmin.map(({ stabbr, doses_admin_total, ...rest }) => {
      return {
        name: stabbr,
        value: doses_admin_total,
        ...rest,
      };
    })
  );
}

export function getWorldVaccineAdministeredData() {
  return getCachedData(`world-vaccine-administered-data`, () =>
    WorldVaccineDosesAdmin.map(({ country, doses_admin_total, ...rest }) => {
      return {
        name: country,
        value: doses_admin_total,
        ...rest,
      };
    })
  );
}
