import React from 'react';
import axios from 'axios';

import { LocationContext } from '~/contexts';
import { getStateInfo } from '~/utils/LocationHelper';

export default () => {
  const { county, setCounty, state, setState, country, setCountry } = React.useContext(LocationContext);
  React.useEffect(() => {
    if (county != null) {
      localStorage.setItem('location-county', county);
    }
  }, [county]);

  React.useEffect(() => {
    if (state != null && !['Wuhan Evacuee', 'Grand Princess', 'Diamond Princess'].includes(state)) {
      localStorage.setItem('location-state', state);
    }
    setCounty('');
  }, [state]);

  React.useEffect(() => {
    if (country != null) {
      localStorage.setItem('location-country', country);
    }
    setState('');
  }, [country]);

  React.useEffect(() => {
    setCountry(localStorage.getItem('location-country') || 'us');
    setState(localStorage.getItem('location-state') || '');
    setCounty(localStorage.getItem('location-county') || '');
  }, []);

  function clearAll() {
    setCountry('');
  }

  return { county, setCounty, state, setState, country, setCountry, clearAll };
};
