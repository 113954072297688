import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import url from 'url';

import zones from '~/data/zones';

dayjs.extend(utc);

export function getZoneName() {
  try {
    if (!global.Intl) {
      require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], function(require) {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        return getZoneNameUsingIntl();
      });
    } else {
      return getZoneNameUsingIntl();
    }
  } catch (err) {
    return null;
  }
}

function getZoneNameUsingIntl() {
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zone = zones.find(z => z.includes(timeZone));
  if (zone) {
    const zones = zone.split('|')[1];
    const result = zones.split(' ');
    return result.length > 1 ? result[1] : result[0];
  }
  return timeZone;
}

export function StripChinese(text, language) {
  const unicodeRegex = /[\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]+/;

  if (language !== 'zh' && text) {
    return text.replace(unicodeRegex, '');
  }
  return text;
}

export function parseWebsiteName(source_url) {
  if (typeof source_url !== 'string') {
    return null;
  }

  let hostname;
  try {
    hostname = url.parse(source_url).hostname;
  } catch (err) {
    // swallow
  }

  if (_.isEmpty(hostname)) {
    return hostname;
  }
  const segments = hostname.split('.');
  if (segments.length === 1) {
    return segments[0];
  }

  return segments[segments.length - 2];
}

export function getLatestDate() {
  return dayjs()
    .utcOffset(-8)
    .format('YYYY/M/D');
}

export function thousandSeparate(value) {
  value = Number(value);
  if (Number.isNaN(value)) {
    return '';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberWithPrefix(value) {
  if (value > 0) {
    return `+${value}`;
  }
  return value;
}

export function formatLargeNumber(value, lang = 'en') {
  // TODO: support rounding with precision as a param
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const BILLION = 1000000000;
  const WAN = 10000;
  const YI = 100000000;

  value = Number(value);
  if (Number.isNaN(value)) {
    return '';
  }

  const prefix = value < 0 ? '-' : '';
  value = Math.abs(value);

  if (lang === 'zh') {
    if (_.inRange(value, WAN, YI)) {
      return `${prefix}${value / WAN}万`;
    }

    if (value >= YI) {
      return `${prefix}${value / YI}亿`;
    }
  } else {
    if (_.inRange(value, THOUSAND, MILLION)) {
      return `${prefix}${value / THOUSAND}k`;
    }

    if (_.inRange(value, MILLION, BILLION)) {
      return `${prefix}${value / MILLION}m`;
    }

    if (value >= BILLION) {
      return `${prefix}${value / BILLION}b`;
    }
  }

  return `${prefix}${value}`;
}

export function toCase1m(confirmed, population) {
  return parseInt((confirmed / population) * 1000000);
}

export function toDeath10m(death, population) {
  return parseInt((death / population) * 1000 * 1000 * 10);
}

export function formatAxisLable(value) {
  const date = dayjs(value);
  return date.isValid() ? date.format('M/D') : value;
}
