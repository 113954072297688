import React from 'react';

const cookieKeys = {
  OFFER_COOKIE_TOKEN_KEY: 'auth_str',
  WECHAT_SYNCED: 'wechat_sync',
  API_TOKEN: 'api-token',
};

const DOMAIN_ROOT = 'https://www.1point3acres.com/bbs';
const API_ENDPOINT = 'https://api.1point3acres.com';
const AVATAR_ROOT = 'https://avatar.1point3acres.cn';
export const TREND_OFFSET = 4;

const MAP_RANGES = [
  { min: 10000 },
  { min: 1000, max: 10000 },
  { min: 500, max: 1000 },
  { min: 100, max: 500 },
  { max: 100, min: 1 },
];
const MAP_COLORS = ['#fff0e7', '#ffd1c0', '#ffb194', '#ff8f62', '#ff6600', '#ff2600'];

const bbsEndpoint = {
  THREAD: tid => `${DOMAIN_ROOT}/thread-${tid}-1-1.html`,
};

const ECHARTS_DATA_ZOOM_HANDLE_ICON = 'image:///images/handle_24.svg';

export function toEmoji(unicode) {
  const unicodePoint = Number(unicode.replace(/^&#|;$/g, ''));
  return String.fromCodePoint(`0x${unicodePoint.toString(16)}`);
}

export function replaceEmojis(text) {
  return text.replace(/&#.+?;/g, toEmoji);
}

export const getAvatarAddress = (uid, size = 'small', reload = false) => {
  // 已加防盗链，本地无法打开
  if (uid === undefined) {
    return `${AVATAR_ROOT}/no_avatar_small.jpg!c`;
  }
  const paddingUID = String(uid).padStart(9, '0');
  const dir1 = paddingUID.substring(0, 3);
  const dir2 = paddingUID.substring(3, 5);
  const dir3 = paddingUID.substring(5, 7);
  const dir4 = paddingUID.substring(7);
  const url = `${AVATAR_ROOT}/${dir1}/${dir2}/${dir3}/${dir4}_avatar_${size}.jpg!c`;
  if (reload) {
    return `${url}?date=${new Date().getTime()}`;
  }
  return url;
};

export const DISCLAIMER = `This website and its contents herein, including all data, mapping, and analysis, copyright ${new Date().getFullYear()} 1Point3Acres, LLC, all rights reserved, is provided to the public strictly for general information purposes only. All the information was collected from multiple publicly available data sources that do not always agree. While we'll try our best to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, with respect to the website or the information. We do not bear any legal responsibility for any consequence caused by the use of information provided. We strictly prohibit unauthorized use of this website in commerce or reliance on this website for medical guidance. Reliance on the website for medical guidance or use of the website in commerce is strictly prohibited. 1Point3Acres hereby disclaims any and all representations and warranties with respect to the website, including accuracy, fitness for use, and merchantability. Screen shots of the website are permissible so long as you provide appropriate credit.
`;

export const ADVERTISER_DISCLOSURE = `1Point3Acres, LLC is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com.`;

export { DOMAIN_ROOT, cookieKeys, bbsEndpoint, API_ENDPOINT, MAP_RANGES, MAP_COLORS, ECHARTS_DATA_ZOOM_HANDLE_ICON };

export const DARK_TEXT_COLOR = '#efefef';
