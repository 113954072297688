/* plain javascript utilities and costants shared between nextjs and scripts */

exports.rehyrateUSData = (data, startDate) => {
  return data.map(({ entries, ...rest }) => {
    return {
      ...rest,
      entries: entries.map((num, i) => {
        const date = new Date(startDate.getTime() + 86400000 * i);
        return [`${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()}`, num];
      }),
    };
  });
};

exports.rehyrateCanadaData = (data, startDate) => {
  return data.map(({ entries, ...rest }) => {
    return {
      ...rest,
      entries,
    };
  });
};

exports.KeyDates = {
  US_CASE_START_DATE: new Date(Date.UTC(2020, 0, 21)),
  US_DEATH_START_DATE: new Date(Date.UTC(2020, 1, 28)),
  US_CURED_START_DATE: new Date(Date.UTC(2020, 2, 11)),
  US_LAZY_LOAD_START_DATE: new Date(Date.UTC(2022, 7, 25)),
  US_LAZY_LOAD_START_DATE_1: new Date(Date.UTC(2021, 8, 1)),
  CA_CASE_START_DATE: new Date(Date.UTC(2020, 0, 27)),
  CA_DEATH_START_DATE: new Date(Date.UTC(2020, 2, 10)),
  CA_CURED_START_DATE: new Date(Date.UTC(2020, 2, 10)),
  CA_LAZY_LOAD_START_DATE: new Date(Date.UTC(2022, 7, 25)),
  CA_LAZY_LOAD_START_DATE_1: new Date(Date.UTC(2021, 8, 1)),
};
