import _ from 'lodash';

import { WORLD_SUMMARY, CUMULATIVE_WORLD_DATA, getCumulativeData, getStateTable } from '~/utils/WorldHelper';
import {
  getConfirmedIncreaseData,
  getConfirmedOneWeekIncreaseData,
  getConfirmedTwoWeekIncreaseData,
  getCuredIncreaseData,
  getDeathIncreaseData,
  getStateMapData,
  getCountryData,
  getUSVaccineDistributedData,
  getUSVaccineAdministeredData,
  getWorldVaccineAdministeredData,
} from '~/utils/CaseHelper';
import {
  getUSHospitalizedSummrayData,
  getStateHospitalizedSummrayData,
  getSummaryPositiveRate,
} from '~/utils/TestHelper';
import overall from '~/data/overall';
import { decrypt } from '~/utils/Crypto';
import regionData from '~/data/region-data-v2.json';
import worldData from '~/data/world-data.json';
import USStatsMap from '~/data/US-Stats-map';
import CanadaStatsMap from '~/data/Canada-Stats-map';
import regionNameZh from '~/data/region-name-zh';

export function getStateInfo(name) {
  const groupByName = _.groupBy(_.merge(USStatsMap, CanadaStatsMap), 'provinceName');
  return groupByName[name]?.[0];
}

export function getWorldSummary(country) {
  return [
    [WORLD_SUMMARY.confirmed, WORLD_SUMMARY.increaseConfirmed],
    [WORLD_SUMMARY.death, WORLD_SUMMARY.increaseDead],
    country === 'us' ? ['-', '-'] : [WORLD_SUMMARY.recovered, WORLD_SUMMARY.increaseCured],
    country === 'us' ? ['-', '-'] : [WORLD_SUMMARY.fatality, WORLD_SUMMARY.increaseFatality],
  ];
}

export function getCountrySummary(country, showTableData) {
  if (!country) return null;

  if (/^(us|ca)$/i.test(country)) {
    country = country.toLowerCase();
    const [death, confirmed] = [decrypt(overall[`${country}DeadCount`]), decrypt(overall[`${country}ConfirmedCount`])];
    const [increaseDeath, increaseConfirmed, oneWeekIncreaseConfirmed, twoWeekIncreaseConfirmed] = [
      getDeathIncreaseData(country),
      getConfirmedIncreaseData(country),
      getConfirmedOneWeekIncreaseData(country),
      getConfirmedTwoWeekIncreaseData(country),
    ];
    const [yesterdayDeath, yesterdayConfirmed] = [death - increaseDeath, confirmed - increaseConfirmed];

    const [fatality, yesterdayFatality] = [
      _.round((death / confirmed) * 100, 1),
      _.round((yesterdayDeath / yesterdayConfirmed) * 100, 1),
    ];

    return [
      [confirmed, increaseConfirmed, oneWeekIncreaseConfirmed, twoWeekIncreaseConfirmed],
      [death, increaseDeath],
      country === 'ca' || showTableData
        ? [decrypt(overall[`${country}CuredCount`]), getCuredIncreaseData(country)]
        : getUSHospitalizedSummrayData(),
      country === 'ca' || showTableData
        ? [fatality, _.round(fatality - yesterdayFatality, 1)]
        : getSummaryPositiveRate(),
    ];
  }
  const data = _.groupBy(CUMULATIVE_WORLD_DATA, 'name')?.[country]?.[0];
  if (data) {
    return [
      [data.confirmed, data.increaseConfirmed, data.oneWeekIncreaseConfirmed, data.twoWeekIncreaseConfirmed],
      [data.death, data.increaseDead],
      [data.recovered, data.increaseCured],
      [data.fatality, data.increaseFatality],
    ];
  }
  return null;
}

export function getStateSummary(country, state) {
  const states = getCumulativeData(regionData[country]) || getStateTable(country);
  const data = _.groupBy(states, 'name')?.[state]?.[0];
  if (data) {
    return [
      [data.confirmed, data.increaseConfirmed],
      [data.death, data.increaseDead],
      country === 'us' ? getStateHospitalizedSummrayData(state) : [data.recovered, data.increaseCured],
      country === 'us' ? getSummaryPositiveRate(state) : [data.fatality],
    ];
  }
  return null;
}

export function getCountySummary(country = '', state, county) {
  country = country.toLowerCase();
  if (!['us', 'ca'].includes(country)) return null;

  const countries = getStateMapData(state, country);
  const data = countries?.[county];
  if (data) {
    const { value: confirmed, deadCount } = data;
    return [
      [confirmed, data.increaseConfirmed],
      [deadCount, data.increaseDead],
      country === 'us' ? ['-', '-'] : [data.curedCount, data.increaseCured],
      country === 'us' ? ['-', '-'] : [_.round((deadCount / confirmed) * 100, 1)],
    ];
  }
  return null;
}

function toShortCountryText(country) {
  if (['us', 'ca'].includes(country)) {
    return country.toUpperCase();
  }
  return country;
}

export function getSummaries(country, state, county) {
  return [
    [county, getCountySummary(country, state, county)],
    [state, getStateSummary(country, state)],
    [toShortCountryText(country), getCountrySummary(country)],
    ['World', getWorldSummary(country)],
  ];
}

function getVaccineAdministeredAggregation(vaccineAdministerd) {
  const totalAdministered = _.sumBy(vaccineAdministerd, item => _.toNumber(item.value) || 0);
  const totalPeopleOneDose = _.sumBy(vaccineAdministerd, item => _.toNumber(item.people_total) || 0);
  const totalPeopleTwoDoses = _.sumBy(vaccineAdministerd, item => _.toNumber(item.people_total_2nd_dose) || 0);
  const totalAdministeredIncrease = _.sumBy(
    vaccineAdministerd,
    item => _.toNumber(item.doses_admin_total_increase) || 0
  );
  const totalPeopleOneDoseIncrease = _.sumBy(vaccineAdministerd, item => _.toNumber(item.people_total_increase) || 0);
  const totalPeopleTwoDosesIncrease = _.sumBy(
    vaccineAdministerd,
    item => _.toNumber(item.people_total_2nd_dose_increase) || 0
  );
  return {
    totalAdministered,
    totalPeopleOneDose,
    totalPeopleTwoDoses,
    totalAdministeredIncrease,
    totalPeopleOneDoseIncrease,
    totalPeopleTwoDosesIncrease,
  };
}

export function getUSVaccineSummaries(state) {
  // const vaccineDistrbuted = getUSVaccineDistributedData();
  // const totalDistributed = _.sumBy(
  //   _.filter(vaccineDistrbuted, item => !_.isNil(item.name)),
  //   item => item.value || 0
  // );
  // let stateDistrbuted = null;
  // if (!_.isNil(state)) {
  //   const stateItem = _.find(vaccineDistrbuted, item => item.name === state);
  //   if (stateItem) {
  //     stateDistrbuted = stateItem.value;
  //   }
  // }

  const vaccineAdministerd = getUSVaccineAdministeredData();
  const usVaccineAdministerdAggregation = getVaccineAdministeredAggregation(vaccineAdministerd);

  let stateAdministered = null;
  let statePeopleOneDose = null;
  let statePeopleTwoDoses = null;
  let stateAdministeredIncrease = null;
  let statePeopleOneDoseIncrease = null;
  let statePeopleTwoDosesIncrease = null;
  if (!_.isNil(state)) {
    const stateItem = _.find(vaccineAdministerd, item => item.name === state);
    if (stateItem) {
      stateAdministered = stateItem.value;
      statePeopleOneDose = stateItem.people_total;
      statePeopleTwoDoses = stateItem.people_total_2nd_dose;
      stateAdministeredIncrease = stateItem.doses_admin_total_increase;
      statePeopleOneDoseIncrease = stateItem.people_total_increase;
      statePeopleTwoDosesIncrease = stateItem.people_total_2nd_dose_increase;
    }
  }

  const worldVaccineAdministerd = getWorldVaccineAdministeredData();
  const worldVaccineAdministerdAggregation = getVaccineAdministeredAggregation(worldVaccineAdministerd);

  return [
    [
      state,
      _.isEmpty(state)
        ? null
        : [
            // stateDistrbuted || undefined,
            [stateAdministered || undefined, stateAdministeredIncrease || undefined],
            [statePeopleOneDose || undefined, statePeopleOneDoseIncrease || undefined],
            [statePeopleTwoDoses || undefined, statePeopleTwoDosesIncrease || undefined],
            // _.round((_.toNumber(stateAdministered) / _.toNumber(stateDistrbuted)) * 100 || 0, 1) || undefined,
          ],
    ],
    [
      'US',
      [
        // totalDistributed || undefined,
        [
          usVaccineAdministerdAggregation.totalAdministered || undefined,
          usVaccineAdministerdAggregation.totalAdministeredIncrease || undefined,
        ],
        [
          usVaccineAdministerdAggregation.totalPeopleOneDose || undefined,
          usVaccineAdministerdAggregation.totalPeopleOneDoseIncrease || undefined,
        ],
        [
          usVaccineAdministerdAggregation.totalPeopleTwoDoses || undefined,
          usVaccineAdministerdAggregation.totalPeopleTwoDosesIncrease || undefined,
        ],
        // _.round((_.toNumber(totalAdministered) / _.toNumber(totalDistributed)) * 100 || 0, 1) || undefined,
      ],
    ],
    [
      'World',
      [
        [
          worldVaccineAdministerdAggregation.totalAdministered || undefined,
          worldVaccineAdministerdAggregation.totalAdministeredIncrease || undefined,
        ],
        [
          worldVaccineAdministerdAggregation.totalPeopleOneDose || undefined,
          worldVaccineAdministerdAggregation.totalPeopleOneDoseIncrease || undefined,
        ],
        [
          worldVaccineAdministerdAggregation.totalPeopleTwoDoses || undefined,
          worldVaccineAdministerdAggregation.totalPeopleTwoDosesIncrease || undefined,
        ],
      ],
    ],
  ];
}

export function getCountries() {
  return Object.keys(worldData).map(c => (['Canada', 'US'].includes(c) ? c.toLowerCase().slice(0, 2) : c));
}

export function toLanguageText(value) {
  return (
    {
      en: 'English',
      zh: '中文',
      fr: 'Français',
      jp: '日本語',
      es: 'español',
    }[value] || value
  );
}

export function toCountryText(value, lang) {
  if (lang === 'zh') {
    return (
      {
        us: '美国',
        ca: '加拿大',
        World: '全球',
      }[value] ||
      regionNameZh[value] ||
      value
    );
  }
  return (
    {
      us: 'United States',
      ca: 'Canada',
    }[value] || value
  );
}

export const FEATURED_REGIONS = [
  { tag: 'us', iso2: 'us' },
  { tag: 'ca', iso2: 'ca' },
  { tag: 'China', iso2: 'cn' },
  { tag: 'Spain', iso2: 'es' },
  { tag: 'Austria', iso2: 'at' },
  { tag: 'Belgium', iso2: 'be' },
  { tag: 'Switzerland', iso2: 'ch' },
  { tag: 'Germany', iso2: 'de' },
  { tag: 'United Kingdom', iso2: 'gb' },
  { tag: 'India', iso2: 'in' },
  { tag: 'Japan', iso2: 'jp' },
  { tag: 'Korea, South', iso2: 'kr' },
  { tag: 'Malaysia', iso2: 'my' },
  { tag: 'Netherlands', iso2: 'nl' },
  { tag: 'Russia', iso2: 'ru' },
  { tag: 'Saudi Arabia', iso2: 'sa' },
  { tag: 'Sweden', iso2: 'se' },
  { tag: 'Mexico', iso2: 'mx' },
  { tag: 'Peru', iso2: 'pe' },
  { tag: 'Brazil', iso2: 'br' },
  { tag: 'Pakistan', iso2: 'pk' },
  { tag: 'South Africa', iso2: 'za' },
  { tag: 'Portugal', iso2: 'pt' },
  { tag: 'France', iso2: 'fr' },
  { tag: 'Italy', iso2: 'it' },
  { tag: 'Bolivia', iso2: 'bo' },
  { tag: 'Argentina', iso2: 'ar' },
  { tag: 'Colombia', iso2: 'co' },
  { tag: 'Costa Rica', iso2: 'cr' },
  { tag: 'Cuba', iso2: 'cu' },
  { tag: 'Czechia', iso2: 'cz' },
  { tag: 'Honduras', iso2: 'hn' },
  { tag: 'Indonesia', iso2: 'id' },
  { tag: 'Ireland', iso2: 'ie' },
  { tag: 'Nigeria', iso2: 'ng' },
  { tag: 'Philippines', iso2: 'ph' },
  { tag: 'Poland', iso2: 'pl' },
  { tag: 'Romania', iso2: 'ro' },
  { tag: 'Thailand', iso2: 'th' },
  { tag: 'Ecuador', iso2: 'ec' },
  { tag: 'Slovenia', iso2: 'si' },
  { tag: 'Ukraine', iso2: 'ua' },
  { tag: 'Croatia', iso2: 'hr' },
  { tag: 'Estonia', iso2: 'ee' },
  { tag: 'Albania', iso2: 'al' },
  { tag: 'Hungary', iso2: 'hu' },
  { tag: 'Algeria', iso2: 'dz' },
  { tag: 'Morocco', iso2: 'ma' },
  { tag: 'Greece', iso2: 'gr' },
];

export function getFlagUrl(iso2) {
  if (iso2 === 'in') {
    return 'https://user-images.githubusercontent.com/13071306/82846253-caa32e00-9e9c-11ea-972f-a6c36afe55d8.png';
  }

  return `https://hatscripts.github.io/circle-flags/flags/${iso2}.svg`;
}
