import _ from 'lodash';
import fromEntries from 'object.fromentries';
import dayjs from 'dayjs';

import testDaily from '~/data/test-states-daily';
import data from '~/data/test-states-overall';
import populationData from '~/data/state-population';
import stateMaxHospitalization from '~/data/test-states-max-hospitalization';

export function getCachedData(key, getData) {
  if (global[key] == null) {
    const data = getData();
    global[key] = data;
  }
  return global[key];
}

const formatTestingDate = date => {
  const dateStr = date.toString();
  return dayjs(`${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6)}`).format('YYYY/M/D');
};

export function to1m(value, population) {
  return parseInt((value / population) * 1000000);
}

export function getTotalBarChartData(state) {
  return getCachedData(`testing-total-bar-chart-data-${state}`, () => {
    const data = Object.entries(
      _.groupBy(
        _.filter(testDaily, d => d.date >= 20200321).filter(v => (state ? v.state === state : true)),
        'date'
      )
    )
      .map(([k, v]) => ({
        date: k,
        positiveIncrease: _.sumBy(v, 'positiveIncrease'),
        negativeIncrease: _.sumBy(v, 'negativeIncrease'),
        totalTestResultsIncrease: _.sumBy(v, 'totalTestResultsIncrease'),
      }))
      .sort((a, b) => a.date - b.date)
      .reduce(
        (r, v) => {
          r['x'].push(formatTestingDate(v.date));
          r['positive'].push(v.positiveIncrease);
          r['negative'].push(v.negativeIncrease);
          r['total'].push(v.totalTestResultsIncrease);
          return r;
        },
        {
          x: [],
          positive: [],
          negative: [],
          total: [],
        }
      );

    const averageTestingData = _.map(_.range(data.positive.length), idx => {
      const sevenDayPositive = _.sum(_.slice(data.positive, Math.max(idx - 7, 0), idx));
      const sevenDayNegative = _.sum(_.slice(data.negative, Math.max(idx - 7, 0), idx));
      const sevenDayTotal = _.sum(_.slice(data.total, Math.max(idx - 7, 0), idx));
      return {
        sevenDayAveragePositive: sevenDayPositive / 7,
        sevenDayAverageNegative: sevenDayNegative / 7,
        sevenDayAverageTotal: sevenDayTotal / 7,
        sevenDayAveragePositiveRate: (sevenDayPositive / sevenDayTotal) * 100,
      };
    });

    data.sevenDayAveragePositive = _.map(averageTestingData, 'sevenDayAveragePositive');
    data.sevenDayAverageNegative = _.map(averageTestingData, 'sevenDayAverageNegative');
    data.sevenDayAverageTotal = _.map(averageTestingData, 'sevenDayAverageTotal');
    data.sevenDayAveragePositiveRate = _.map(averageTestingData, 'sevenDayAveragePositiveRate');

    return data;
  });
}

export function getStateBarChartData(dimension) {
  return getCachedData(`testing-state-bar-chart-data-${dimension}`, () => {
    return Object.entries(_.groupBy(testDaily, 'state'))
      .filter(([k]) => populationData[k])
      .map(([k, v]) => [k, v.slice(0, 7)])
      .map(([k, v]) => {
        const [population, positive, negative, total] = [
          populationData[k],
          _.sumBy(v, 'positiveIncrease'),
          _.sumBy(v, 'negativeIncrease'),
          _.sumBy(v, 'totalTestResultsIncrease'),
        ];
        return {
          name: k,
          positive: dimension === 'test1m' ? to1m(positive, population.replace(/,/g, '')) : positive,
          negative: dimension === 'test1m' ? to1m(negative, population.replace(/,/g, '')) : negative,
          total: dimension === 'test1m' ? to1m(total, population.replace(/,/g, '')) : total,
        };
      })
      .sort((a, b) => b.positive + b.negative - (a.positive + a.negative))
      .reduce(
        (r, v) => {
          r['x'].push(v.name);
          r['positive'].push(v.positive);
          r['negative'].push(v.negative);
          r['total'].push(v.total);
          return r;
        },
        {
          x: [],
          positive: [],
          negative: [],
          total: [],
        }
      );
  });
}

export function getLastSevenDaysHospitalizedData() {
  const data = _.groupBy(testDaily, 'date');
  const sevenDayData = Object.values(data).slice(-7);
  return _.reverse(sevenDayData);
}

export function getUSHospitalizedSummrayData() {
  const sevenDays = getLastSevenDaysHospitalizedData();
  const [today, yesterday, ...rest] = sevenDays;

  if (today) {
    const todayData = _.sumBy(today, 'hospitalizedCurrently');
    const sevenDayAvgHostpitalizedCurrently = _.round(
      _.sum(_.map(sevenDays, dailyData => _.sumBy(dailyData, 'hospitalizedCurrently'))) / 7
    );
    return [
      todayData,
      todayData - _.sumBy(yesterday, 'hospitalizedCurrently'),
      stateMaxHospitalization.US.hospitalized,
      sevenDayAvgHostpitalizedCurrently,
    ];
  }
  return ['-', '-', '-', '-'];
}

export function getStateHospitalizedSummrayData(state) {
  const data = getLastSevenDaysHospitalizedData();
  if (data[0]) {
    const [todayData, yesterdayData, ...rest] = data.map(d => d.find(v => v.state === state)?.hospitalizedCurrently);

    return [todayData, todayData - yesterdayData];
  }
  return ['-', '-'];
}

export function getUSHospitalizedChartData() {
  const data = Object.entries(_.groupBy(testDaily, 'date')).map(([date, v]) => ({
    date,
    hospitalizedCurrently: _.sumBy(v, 'hospitalizedCurrently'),
  }));

  return {
    x: _.map(data, d => formatTestingDate(d.date)),
    'Currently Hospitalized': _.map(data, d => d.hospitalizedCurrently),
  };
}

export function getStateChartData(state) {
  return getCachedData(`testing-state-chart-data-${state}`, () => {
    const stateDaily = _.sortBy(
      _.filter(testDaily, d => d.state === state && d.date >= 20200321),
      d => d.date
    );
    const averageTestingData = _.map(_.range(stateDaily.length), idx => {
      const sevenDayPositive = _.sumBy(_.slice(stateDaily, Math.max(idx - 7, 0), idx), item => item.positiveIncrease);
      const sevenDayNegative = _.sumBy(_.slice(stateDaily, Math.max(idx - 7, 0), idx), item => item.negativeIncrease);
      const sevenDayTotal = _.sumBy(
        _.slice(stateDaily, Math.max(idx - 7, 0), idx),
        item => item.totalTestResultsIncrease
      );
      return {
        sevenDayAveragePositive: sevenDayPositive / 7,
        sevenDayAverageNegative: sevenDayNegative / 7,
        sevenDayAverageTotal: sevenDayTotal / 7,
        sevenDayAveragePositiveRate: (sevenDayPositive / sevenDayTotal) * 100,
      };
    });

    const testingChartData = {
      x: _.map(stateDaily, d => formatTestingDate(d.date)),
      positive: _.map(stateDaily, d => d.positiveIncrease),
      negative: _.map(stateDaily, d => d.negativeIncrease),
      total: _.map(stateDaily, d => d.totalTestResultsIncrease),
      sevenDayAveragePositive: _.map(averageTestingData, 'sevenDayAveragePositive'),
      sevenDayAverageNegative: _.map(averageTestingData, 'sevenDayAverageNegative'),
      sevenDayAverageTotal: _.map(averageTestingData, 'sevenDayAverageTotal'),
      sevenDayAveragePositiveRate: _.map(averageTestingData, 'sevenDayAveragePositiveRate'),
    };

    const hospitalizedChartData = {
      x: _.map(stateDaily, d => formatTestingDate(d.date)),
      'Currently Hospitalized': _.map(stateDaily, d => d.hospitalizedCurrently),
    };

    return {
      testingChartData,
      hospitalizedChartData,
    };
  });
}

export function getStateTableData(stateShortName) {
  return getCachedData(`testing-state-table-data-${stateShortName}`, () => {
    const stateSevenDayData = getStateSevenDayData();
    const tableData = _.map(
      data.filter(d => !stateShortName || d.state === stateShortName).sort((a, b) => b.positive - a.positive),
      item => ({
        ...item,
        ...stateSevenDayData[item.state],
        maxHospitalized: stateMaxHospitalization[item.state].hospitalized,
      })
    );
    return tableData;
  });
}

function getStateSevenDayData() {
  return getCachedData('testing-recent-week-data', () => {
    return fromEntries(
      _.map(_.groupBy(testDaily, 'state'), (value, key) => {
        const records = _.sortBy(
          _.filter(value, d => d.date >= 20200321),
          d => d.date
        );
        const recentWeek = _.slice(records, records.length - 7);
        const sevenDayPositive = _.sumBy(recentWeek, 'positiveIncrease');
        const sevenDayNegative = _.sumBy(recentWeek, 'negativeIncrease');
        const sevenDayTotal = _.sumBy(recentWeek, 'totalTestResultsIncrease');
        return [
          key,
          {
            sevenDayAveragePositive: _.round(sevenDayPositive / 7),
            sevenDayAverageNegative: _.round(sevenDayNegative / 7),
            sevenDayTotal,
            sevenDayAveragePositveRate: _.round((sevenDayPositive / sevenDayTotal) * 100, 1),
          },
        ];
      })
    );
  });
}

export function getSummaryPositiveRate(state = null) {
  const data = getTotalBarChartData(state);
  const [yesterday, today] = data.sevenDayAveragePositiveRate.slice(-2);
  if (today) {
    return [today.toFixed(1), (today - yesterday).toFixed(1)];
  }
  return ['-', '-'];
}
