import _ from 'lodash';
import dayjs from 'dayjs';
import fromEntries from 'object.fromentries';

import worldData from '~/data/world-data.json';
import worldTestingData from '~/data/world_testing_data.json';
import worldPopulation from '~/data/world-population';
import { TREND_OFFSET } from '~/utils/Constants';
import { toCase1m, toDeath10m } from '~/utils.js';
import { formatStateTableData, getCountryData, getLineChartData } from '~/utils/CaseHelper';

const START_DATE = '2020-01-22';

export const WORLD_SERIES = getWorldSeries();
export const CUMULATIVE_WORLD_DATA = getCumulativeData(worldData);
export const WORLD_SUMMARY = {
  ...toCumulative(worldData.World),
  population: worldPopulation.World,
};

export function toCumulative(data) {
  const confirmed = _.last(data.confirmed) || 0;
  const death = _.last(data.deaths) || 0;
  const recovered = _.last(data.recovered) || 0;

  const [yesterdayDeath, yesterdayConfirmed] = [_.nth(data.deaths, -2), _.nth(data.confirmed, -2)];
  const oneWeekAgoConfirmed = _.nth(data.confirmed, -8) || 0;
  const twoWeeksAgoConfirmed = _.nth(data.confirmed, -15) || 0;

  const [fatality, yesterdayFatality] = [
    confirmed ? _.round((death / confirmed) * 100, 1) : 0,
    _.round((yesterdayDeath / yesterdayConfirmed) * 100, 1),
  ];

  return {
    confirmed,
    death,
    dead: death,
    recovered,
    cured: recovered,
    active: confirmed - death - recovered,
    fatality,
    increaseConfirmed: confirmed - yesterdayConfirmed,
    oneWeekIncreaseConfirmed: confirmed - oneWeekAgoConfirmed,
    twoWeekIncreaseConfirmed: confirmed - twoWeeksAgoConfirmed,
    increaseDead: death - yesterdayDeath,
    increaseCured: recovered - _.nth(data.recovered, -2),
    increaseFatality: _.round(fatality - yesterdayFatality, 1),
  };
}

export function getCumulativeData(data, exclude = 'World') {
  if (data == null) return null;
  return Object.entries(fromEntries(Object.entries(data).filter(([k, v]) => k !== exclude)))
    .map(([k, v]) => {
      const population = worldPopulation[k] || 0;
      const cumulative = toCumulative(v);
      return {
        name: k,
        population,
        ...cumulative,
        case1m: toCase1m(cumulative.confirmed, population),
        death10m: toDeath10m(cumulative.death, population),
        endDate: v.end_date,
      };
    })
    .sort((a, b) => b.confirmed - a.confirmed);
}

export function formatCountryData(data, startDate) {
  return {
    increase: data.map((v, i, list) => v - list[i - 1] || 0),
    x: Array.from({ length: data.length }).map((_, i) =>
      dayjs(startDate || START_DATE)
        .add(i, 'd')
        .format('YYYY/M/D')
    ),
    total: data,
  };
}

export function getWorldSeries() {
  const { confirmed, deaths, recovered } = worldData['World'];
  return { confirmed, deaths, recovered };
}

export function getTrendData(key) {
  return fromEntries(
    Object.entries(worldData)
      .filter(([k, v]) => v[key] && v[key].length)
      .map(([k, v]) => [k, { data: v[key] }])
      .concat([['x', _.range(1, worldData.World[key].length + 1 + TREND_OFFSET)]])
  );
}

export function filterTrendDataByCountries(data, countries, includeWorld = true) {
  return (includeWorld ? ['World'] : []).concat(countries).reduce(
    (r, v) => {
      if (data[v]) {
        r[v] = data[v];
      }
      return r;
    },
    { x: data.x }
  );
}

export function toShortCountryName(name) {
  return (
    {
      Canada: 'ca',
      US: 'us',
    }[name] || name
  );
}

export function getStateTable(country) {
  const { confirmedData, deathCaseData, curedCaseData, mapData } = getCountryData(toShortCountryName(country));
  if (confirmedData) {
    return formatStateTableData(
      confirmedData,
      deathCaseData,
      curedCaseData,
      _.groupBy(mapData, 'provinceShortName')
    ).map(v => ({
      ...v,
      name: v.provinceShortName,
      confirmed: v.confirmed,
      death: v.dead,
      active: v.confirmed - v.dead - v.cured,
      recovered: v.cured,
      population: v.population,
      fatality: _.round((v.dead / v.confirmed) * 100, 1),
      case1m: toCase1m(v.confirmed, v.population),
      death10m: toDeath10m(v.dead, v.population),
    }));
  }
  return [];
}

export function toWorldChartData(country, provice, county) {
  country = toShortCountryName(country);
  const countyData = getCountryData(toShortCountryName(country));
  if (countyData) {
    const confirmedData = getLineChartData(countyData.confirmedData, provice, county);
    const deathData = getLineChartData(countyData.deathCaseData, provice, county);
    const recoveredData = getLineChartData(countyData.curedCaseData, provice, county);

    return {
      confirmed: confirmedData.total,
      deaths: deathData.total,
      recovered: recoveredData.total,
      startDate: `2020/${confirmedData.x[0]}`,
    };
  }
}

export function sortTable(data, sortKey, isDesc) {
  return data.sort((a, b) => {
    const [aValue, bValue] = [Number(b[sortKey]) || 0, Number(a[sortKey]) || 0];
    let result = 0;
    if (aValue > bValue) {
      result = 1;
    } else if (aValue < bValue) {
      result = -1;
    }
    return isDesc ? result : ~result + 1;
  });
}

export function getWorldTestData() {
  const data = fromEntries(
    _.map(worldTestingData, (data, country) => {
      const sortedData = _.sortBy(data, item => item[0]);
      const total = _.map(sortedData, item => [item[0], item[1]]);
      const totalPerThousand = _.map(sortedData, item => [item[0], item[3]]);
      return [country, { total, totalPerThousand }];
    })
  );

  data.x = _.map(_.range(dayjs().diff(dayjs('2020-03-01'), 'day') + 10), offset =>
    dayjs('2020-03-01')
      .add(offset, 'day')
      .format('YYYY-MM-DD')
  );

  return data;
}
